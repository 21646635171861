import React, { useState } from "react";
import firebase from "../firebase";
import { auth } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Layout";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };
  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await auth.signInWithPopup(provider);
      // User is now signed in with their Google account
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <Layout
      title="Login"
      description="Welcome back to AhleSunnat Global!
    Log in to your account and experience the convenience of sourcing the finest edible farm products from India. Whether you're a wholesaler, retailer, or individual buyer, our platform offers a seamless browsing experience to discover and order a diverse range of agricultural products. Login now to unlock a world of flavors at your fingertips."
    >
      <h1 className="text-center m-2 p-2">Login Page</h1>
      <div className="text-center">
        <div className="form-signin w-100 m-auto">
          <div className="alert alert-light m-2" role="alert">
            If you are first time on Ahlesunnat Global website{" "}
            <Link className="alert-link" to="/signup">
              Register yourself
            </Link>{" "}
            .
          </div>
          <form onSubmit={handleLogin}>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-floating">
              <input
                type="email"
                className="form-control custom-input"
                id="floatingInput"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlfor="floatingInput">Email address</label>
            </div>
            <div className="form-floating">
              <input
                type="password"
                className="form-control custom-input"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlfor="floatingPassword">Password</label>
            </div>
            <div className="checkbox m-4">
              <label>
                <input type="checkbox" value="remember-me" /> Remember me
              </label>
            </div>
            <button className="w-100 btn btn-lg btn-dark mb-4" type="submit">
              Sign in <i class="fa-solid fa-arrow-right-to-bracket"></i>
            </button>
          </form>
          <button
            className="w-100 btn btn-warning ms-2"
            onClick={handleGoogleLogin}
          >
            <i class="fa-brands fa-google"></i> Sign in with Google <i class="fa-solid fa-arrow-right-to-bracket"></i>
          </button>
          <div className="alert alert-info m-2" role="alert">
            If You lost your password, you can{" "}
            <Link className="alert-link" to="/forgot-password">
              reset your password
            </Link>{" "}
            here.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
