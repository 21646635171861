import React, { useState } from "react";

const Photoslider = ({ imgsrcs }) => {
  const [loadedImages, setLoadedImages] = useState([]);

  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => {
      if (!prevLoadedImages.includes(index)) {
        return [...prevLoadedImages, index];
      }
      return prevLoadedImages;
    });
  };

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide m-2 shadow"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        {imgsrcs.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {imgsrcs.map((imgsrc, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <div className="image-container">
              {!loadedImages.includes(index) && (
                <div className="image-placeholder"></div>
              )}
              <img
                src={imgsrc}
                alt="Ahlesunnat Global Pics"
                className={!loadedImages.includes(index) ? "d-none" : ""}
                onLoad={() => handleImageLoad(index)}
                referrerPolicy="no-referrer"
              />
            </div>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Photoslider;
