import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchBox = () => {
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  const handleSearchToggle = () => {
    setShowSearch(!showSearch);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    navigate(`/search-results?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <>
      <div className="search">
        <input
          className="form-control custom-input"
          type="search"
          placeholder="Search Our products"
          aria-label="Search"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <i className="fas fa-search magnifier"></i>
      </div>
      <div className="search-container">
        {showSearch ? (
          <div className="search-box">
            <button className="back-btn" onClick={handleSearchToggle}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <input
              className="form-control ps-5 custom-input"
              type="search"
              placeholder="Search Our products"
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <i className="fas fa-search magnifier"></i>
          </div>
        ) : (
          <button className="search-btn" onClick={handleSearchToggle}>
            <i className="fas fa-search"></i>
          </button>
        )}
      </div>
    </>
  );
};

export default SearchBox;
