import React, { useState } from "react";
import { auth } from "../firebase";
import firebase from "../firebase";
import Layout from "../Layout";
import { Link } from "react-router-dom";

const SignUp = () => {
  const [Name, setName] = useState("");
  const [Surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSignUp = async (e) => {
    e.preventDefault();
    const displayName = `${Name} ${Surname}`;

    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      const user = userCredential.user;

      // Update the user's display name
      await user.updateProfile({
        displayName: displayName,
      });

      // Clear form fields
      setName("");
      setSurname("");
      setEmail("");
      setPassword("");

      // Redirect or show success message to the user
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await auth.signInWithPopup(provider);
      alert("You have successfully signed up.");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Layout
      title="Sign Up"
      description="Welcome to AhleSunnat Global! Sign up now to create your account and gain access to our wide range of edible farm products. As an export-import company based in India, we specialize in delivering high-quality agricultural products to customers around the world. Join us today and explore the flavors of Indian produce like never before!"
    >
      <h1 className="text-center m-2 p-2">Signup Page</h1>
      <div className="text-center">
        <div className="form-signin w-100 m-auto">
          <form onSubmit={handleSignUp}>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-floating m-2">
              <input
                type="text"
                className="form-control custom-input"
                id="floatingInput"
                placeholder="Sumant"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlfor="floatingInput">Name</label>
            </div>
            <div className="form-floating m-2">
              <input
                type="text"
                className="form-control custom-input"
                id="floatingInput"
                placeholder="Surname"
                value={Surname}
                onChange={(e) => setSurname(e.target.value)}
              />
              <label htmlfor="floatingInput">Surname</label>
            </div>
            <div className="form-floating m-2">
              <input
                type="email"
                className="form-control custom-input"
                id="floatingInput"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlfor="floatingInput">Email address</label>
            </div>
            <div className="form-floating m-2">
              <input
                type="password"
                className="form-control custom-input"
                id="floatingPassword"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlfor="floatingPassword">Password</label>
            </div>
            <button className="w-100 btn btn-dark m-2" type="submit">
              <i class="fa-regular fa-user"></i> Sign Up
            </button>
          </form>

          <button
            className="w-100 btn btn-lg btn-warning m-2"
            onClick={handleGoogleSignUp}
          >
            <i class="fa-brands fa-google"></i> Sign up with Google
          </button>
          <div className="alert alert-info m-2" role="alert">
            If you already have an account{" "}
            <Link className="alert-link" to="/login">
              login here
            </Link>{" "}
            .
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;
