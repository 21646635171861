import React from "react";
import iconPath from "../assets/icons/onlinelogomaker-070423-0133-3618.png";
import { NavLink, Link } from "react-router-dom";
import "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

import SearchBox from "./SearchBox";

const Navbar = ({ profilePic, isLoggedIn }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/logout");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark shadow">
      <div className="container-fluid">
        <button
          className="navbar-toggler me-2 custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <Link className="navbar-brand me-auto" to="/">
          <img
            src={iconPath}
            alt="Ahlesunnat Global"
            width="50"
            height="50"
            className="d-inline-block align-text-center rounded"
          />
          <span className="ms-2">Ahlesunnat Global</span>
        </Link>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" aria-current="page">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/ourproducts" className="nav-link">
                Products
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link">
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link">
                About Us
              </NavLink>
            </li>
            {!isLoggedIn ? (
              <>
                <li className="nav-item">
                  <NavLink to="/signup" className="nav-link">
                    Sign Up
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                </li>
              </>
            ):(<li className="nav-item">
            <span onClick={handleLogout} className="nav-link">
              Logout
            </span>
          </li>)}
          </ul>
        </div>
        <SearchBox/>
        {isLoggedIn && (
          <div className="profilePic">
            <Link to="/profile" className="me-2">
              <img
                src={profilePic}
                alt="mdo"
                width="32"
                height="32"
                className="rounded-circle"
              />
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
